html, body {
    height: 100%;
    width: 100%;
}

.header-badge {
    position: fixed;
    bottom: 10px;
    left: 10px;
    width: 265px;
    height: 144px;
    right: inherit;
    top: inherit;
    box-shadow: 0px 0px 10px rgba(0,0,0,0.8);
}


/*
.slider-content.container {
    background: rgba(0,0,0,0.5);
}
*/



