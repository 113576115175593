.news {
  margin-bottom: 0;
  margin-top: 0;
  padding-bottom: 2rem;
}
.news h2::after {
  content: '';
  width: 4rem;
  height: 2px;
  display: block;
  position: relative;
  margin: 1rem auto 0;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffd100+1,ffffff+78 */
  background: var(--color-primary); /* Old browsers */
}
section.news.news--content {
  background: #eee;
}
.news .container {
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
  align-content: flex-start;
  flex-wrap: wrap;
  padding-bottom: 1rem;
}

.news-wrapper {
  width: 100%;
  padding-bottom: 1rem;
}

.news--boxen {
  display: flex;
  justify-content: space-between;
  align-content: flex-start;
  align-items: stretch;
  flex-direction: row;
  flex-wrap: wrap;
}
section.news.news--content .news--boxen {
  justify-content: flex-start;
}

.news--box {
  width: 100%;
  margin-bottom: 2rem;
}

.news--box {
  text-align: left;
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  align-content: flex-start;
  align-items: flex-start;
  height: auto;
}
.news--box--img-container {
  max-height: 16rem;
  overflow: hidden;
}
.news-date {
  border-bottom: solid 1px #ddddde;
  padding-bottom: 0.5rem;
}
.news--box--content {
  margin-bottom: 1rem;
  background: white;
  padding: 1rem;
}
.news--box--content h3 {
  font-size: 1.3rem;
  font-family: var(--font-light);
  padding-bottom: 1rem;
  padding-top: 1rem;
  margin: 0;
}


.news--box-date {
  margin-top: 1rem;
}

.news--box--link-container {
  position: relative;
  display: block;
  text-align: left;
  width: 100%;
  padding-top: 1rem;
}

@media screen and (min-width: 35.5rem) {

}

@media screen and (min-width: 48rem) {
  section.news.news--content .news--box {
    width: calc(96% / 2);
    margin-right: 2%;
  }

}

@media screen and (min-width: 64rem) {
  section.news.news--content .news--box {
    width: calc(94% / 3);
    margin-right: 2%;
  }
  .news--box--content {
    margin-bottom: 1rem;
  }
}