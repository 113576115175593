.dark-stacked {

}
.dark-body {
  background: #2F3438;
  color: #fff;
  padding: 50px 20px;
  font-size: 1.3rem;
  font-weight: 100;
}
.white-body {
  background:#fff ;
  color: #2F3438;
  padding: 50px 20px;
  font-size: 1.3rem;
  font-weight: 100;
}
.dark-stacked label {
  color: white;
  line-height: 1.8rem;
}

.kdz--form-item {
  display: block;
  width: 100%;
}
.pure-g .kdz--form-item {
 padding-left: 0;
}
.dark-stacked-left {
  text-align: center;
  width: 100%;
  font-size: 1.5rem;
  padding-bottom: 1rem;
}

.dark-stacked-left span {
  color: #9abf00;
}

hr.dark-stacked-hr {
  margin: 50px 0;
}

.dark-stacked-input {
  display: block;
  position: relative;
  width: 100%;
  padding: 0.5rem;
  margin-bottom: 1rem;
  font-size: 1rem;
}
.dark-stacked-input.border-error {
  border: solid 1px red;
}
.dark-stacked-right {
  width: 100%;
}
.dark-stacked .radio > input[type="radio"] + label {
  opacity:0.5;
  font-weight: 700;
}
.dark-stacked .radio > input[type="radio"]:checked + label {
  opacity:1;
}
.radio > input[type="radio"]:not(:checked) + label::before, .checkbox > input[type="checkbox"]:not(:checked) + label::before, .radio > input[type="radio"]:not(:checked) + input[type="hidden"] + label::before, .checkbox > input[type="checkbox"]:not(:checked) + input[type="hidden"] + label::before {
  top: 50%;
  transform: translate(0, -50%);

}
.icheck-primary > input[type="radio"]:checked + label::before, .icheck-primary > input[type="checkbox"]:checked + label::before, .icheck-primary > input[type="radio"]:checked + input[type="hidden"] + label::before, .icheck-primary > input[type="checkbox"]:checked + input[type="hidden"] + label::before {
  top: 50%;
  transform: translate(0, -50%);

}
.dark-stacked-input.dark-stacked-select {
  -webkit-appearance: menulist;
}

.dark-stacked-text-radio-radios {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
}

.dark-stacked-text-radio-radios .radio.icheck-primary {
  margin-right: 2rem;
}
.dark-stacked-white {
  background: white;
}
.dark-stacked--kontaktform-headline {
  font-size: 1.2rem;
  border-bottom: 1px solid #2f3438;
  width: 95%;
  margin-bottom: 20px;
  padding-bottom: 12px;
}
button.kdz--form-submit {
  margin: 0;
  width: 100%;
  color: white;
}

@media screen and (min-width: 48rem) {
  .kdz--form-item {
    padding-left: 50px;
    display: inline-flex;
  }
  .dark-stacked-left {
    text-align: right;
    margin-right: 100px;
    max-width: 400px;
    font-size: 2rem;
    padding-bottom: 0;
  }
  .dark-stacked-input {
    width: 90%;
  }
  .dark-stacked label {
    line-height: 2.2rem;
  }
  button.kdz--form-submit {
    width: 90%;
  }
}