.contact {
  padding: 0 ;
  background: white;
  margin-bottom: 1rem;
}

.maps-container {
  height: 25rem;
}
.contact-container {
  padding-top: 1rem;
  padding-bottom: 1rem;
  width: 100%;
  margin: auto;
}
form#contact {
  width: 100%;
  margin: auto;
}
form#contact p:not(.status-message) {
  font-weight: 400;
  margin-top: 0.5rem;
  margin-bottom: 0.2rem;
  color: #7c7c7c;
}
#label-daten {
  font-size: 14px;
  text-align: left;
}
#label-daten > span {
  padding-left: 1rem;
  display: inline-block;
}
.contact-div-left {
  text-align: left
}
input#contact-datenschutz {
  margin-bottom: auto;
  top: 6px;
  position: absolute;
}
.contact--input-container {
  margin-bottom: 1rem;
}

.pure-form select {
  height: 2.56rem;
}

label {
  color: black;
  padding-bottom: 0;
  margin-bottom: 0;
}

.contact button {
  margin-top: 1rem;
  display: inline-block;
}

#contact-uhrzeit {
  margin-bottom: 0;
}

.status-message {
  font-weight: bold;
  font-size: var(--font-size);
  margin-top: calc(var(--gutter) * 0.5);
  color: #ffffff;
  padding: 0.5rem;
}

.status-message.error {
  background-color: var(--error);
  color: white;
}

.status-message.success {
  background-color: var(--success);
  color: white;
}
.contact-container h2 {
  display: inline-block;
  width: auto;
  margin-bottom: 1rem;
}
.contact-container h2::after {
  content: '';
  width: 80%;
  height: 2px;
  display: inline-block;
  position: relative;
  margin: 1rem auto 0;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffd100+1,ffffff+78 */
  background: var(--font-color); /* Old browsers */
  background: -moz-radial-gradient(center, ellipse cover, var(--font-color) 1%, rgba(255, 255, 255, 0) 78%); /* FF3.6-15 */
  background: -webkit-radial-gradient(center, ellipse cover, var(--font-color) 1%, rgba(255, 255, 255, 0) 78%); /* Chrome10-25,Safari5.1-6 */
  background: radial-gradient(ellipse at center, var(--font-color) 1%, rgba(255, 255, 255, 0) 78%); /* W3C, IE10+, FF16+, Chrome26+, Opera12+, Safari7+ */
  filter: progid:DXImageTransform.Microsoft.gradient( startColorstr='#ffd100', endColorstr='#ffffff',GradientType=1 ); /* IE6-9 fallback on horizontal gradient */
}
@media screen and (min-width: 64rem) {
  form#contact {
    width: 65%;
  }
}