.layout-6--content {
  text-align: center;
}
.layout-6--content h2 {
  text-align: center;
}
.layout-6--content h3 {
  text-align: center;
}
.layout-6--image {
  width: 100%;
  margin-left: auto;
  margin-right: auto;
  display: block;
  position: relative;
}
.layout-6-triangle-right {
  content: '';
  position: absolute;
  bottom: 0;
  right: -1px;
  width: 0px;
  height: 0px;
  -webkit-transform:rotate(360deg);
  border-style: solid;
  border-width: 0 0 50px 300px;
  border-color: transparent transparent #ffffff transparent;
}
.layout-6-triangle-left {
  content: '';
  position: absolute;
  bottom: 0;
  left: -1px;
  width: 0px;
  height: 0px;
  -webkit-transform:rotate(360deg);
  border-style: solid;
  border-width: 50px 0 0 300px;
  border-color: transparent transparent transparent #ffffff;
}
@media screen and (min-width: 35.5rem) {
  .layout-6-triangle-right {
    border-width: 0 0 120px 500px;
  }
  .layout-6-triangle-left {
    border-width: 120px 0 0 500px;
  }
}
@media screen and (min-width: 48rem) {
  .layout-6-triangle-right {
    border-width: 0 0 150px 500px;
  }
  .layout-6-triangle-left {
    border-width: 150px 0 0 500px;
  }
}
@media screen and (min-width: 64rem) {
  .layout-6--image {
    width: 50%;
  }
  .layout-6-triangle-right {
    border-width: 0 0 100px 500px;
  }
  .layout-6-triangle-left {
    border-width: 100px 0 0 500px;
  }
}