.modal {
  background: rgba(25, 25, 25, .95) none repeat scroll 0 0;
  position: fixed;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 50;
  overflow: scroll;
  width: 100%;
  height: 100vh;
  top: -100%;
  transition: top .5s ease-in;
}
.modal.open {
  top: 3rem;
}
.modal-content {
  background: white;
  top: 0;
  width: 100vw;
  padding: 4rem 1.5rem;
  position: relative;
}

.modal-header {
  position: relative;
  margin-bottom: 1rem;
  display: inline-block;
  width: 100%;
}

.modal-header button.close {
  float: right;
  background: transparent;
  border: none;
  font-size: 3rem;
  font-weight: 100;
  cursor: pointer;
  color: var(--font-color);
  line-height: 1.8rem;
}



/* viewport-md 768px */
@media screen and (min-width: 48rem) {
  .modal {
    overflow: hidden;
  }

  .modal-content {
    top: 6rem;
    width: 75vw;
    left: 12.5vw;
    margin-bottom: 4rem;

  }
}

/* viewport-lg 1024px */
@media screen and (min-width: 64rem) {
  .modal-content {
    top: 6rem;
    width: 50vw;
    left: 25vw;
    padding: 2.5rem 3.438rem 2.5rem 2.5rem;
  }
}

/* viewport-lg 1280px */
@media screen and (min-width: 80rem) {

}