.parallax--content {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  align-content: center;
  align-items: center;
  flex-direction: row;
}

.parallax-form-wrapper {
  background: transparent;
  padding: .5rem;
  border: solid 2px white;
  width: 100%;
}
.parallax--content-wk {
  width: 100%;
}
.parallax-form-inner {
  min-height: 65rem;
  background: #ffffff;
}

.step {
  display: block;
  width: 100%;
  height: 100%;
  padding: 1rem;
}

.step h3 {
  color: var(--font-color);
  font-family: var(--font-light);
  line-height: 2rem;
}

.step h4 {
  color: var(--color-primary);
  font-family: var(--font-light);
}

.step-boxen {
  display: flex;
  justify-content: center;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
  width: 100%;
  margin: auto;
  flex-direction: column;
}

.step-box {
  width: 100%;
  height: 7rem;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
  cursor: pointer;
  margin-bottom: 1rem;
  margin-left: 0.5rem;
  transition: background 0.3s ease-in, color 0.3s ease-in;
}
.step-box-checkbox {
  width: 100%;
  height: 7rem;
  border: solid 1px #999b9c;
  color: var(--font-color);
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  padding: 0.5rem;
}

.step-box-checkbox label{
  width: 100%;
  height: 80%;
  cursor: pointer;
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
}
span.step-price {
  margin-left: auto;
  text-align: right;
  font-family: var(--font-normal);
}

/*.step-box:hover {
  border-color: var(--color-primary);
}*/

/*.step-box .change-svg-color {
  fill: var(--font-color);
  transition: fill 0.3s ease-in;
}

.step-box:hover .change-svg-color {
  fill: white
}

.step-box .change-svg-color-invert {
  fill: white
}

.step-box:hover .change-svg-color-invert {
  fill: rgba(253, 196, 0, 0.8);;
  transition: fill 0.3s ease-in;
}*/

.step-box-img img,
.step-box svg {
  height: 5rem;
  width: 5rem;
}

.slider-input {
  width: 70%;
  margin-bottom: 1.5rem;
  text-align: center;
  padding: 0.3rem;
}

.step-box-content {
  margin-top: auto;
  height: 4rem;
}

.noUi-marker.noUi-marker-horizontal.noUi-marker-normal {
  display: none;
}

.noUi-value.noUi-value-horizontal.noUi-value-large {
  content: "ab 25 Jahre";
}

.step-container {
  width: 100%;
  margin: auto;
}

.slider-range-wrapper {
  padding-left: 2rem;
  padding-right: 2rem;
  margin: 5rem auto auto;
  height: 5rem;
}

input[type=range] {
  -webkit-appearance: none;
  margin: 18px 0;
  width: 100%;
}

input[type=range]:focus {
  outline: none;
}

input[type=range]::-webkit-slider-runnable-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  background: #3071a9;
  border-radius: 1.3px;
  border: 0.2px solid #010101;
}

input[type=range]::-webkit-slider-thumb {
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  border: 1px solid #000000;
  height: 36px;
  width: 16px;
  border-radius: 3px;
  background: #ffffff;
  cursor: pointer;
  -webkit-appearance: none;
  margin-top: -14px;
}

input[type=range]:focus::-webkit-slider-runnable-track {
  background: #367ebd;
}

input[type=range]::-moz-range-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  animate: 0.2s;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  background: #3071a9;
  border-radius: 1.3px;
  border: 0.2px solid #010101;
}

input[type=range]::-moz-range-thumb {
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  border: 1px solid #000000;
  height: 36px;
  width: 16px;
  border-radius: 3px;
  background: #ffffff;
  cursor: pointer;
}

input[type=range]::-ms-track {
  width: 100%;
  height: 8.4px;
  cursor: pointer;
  animate: 0.2s;
  background: transparent;
  border-color: transparent;
  border-width: 16px 0;
  color: transparent;
}

input[type=range]::-ms-fill-lower {
  background: #2a6495;
  border: 0.2px solid #010101;
  border-radius: 2.6px;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}

input[type=range]::-ms-fill-upper {
  background: #3071a9;
  border: 0.2px solid #010101;
  border-radius: 2.6px;
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
}

input[type=range]::-ms-thumb {
  box-shadow: 1px 1px 1px #000000, 0px 0px 1px #0d0d0d;
  border: 1px solid #000000;
  height: 36px;
  width: 16px;
  border-radius: 3px;
  background: #ffffff;
  cursor: pointer;
}

input[type=range]:focus::-ms-fill-lower {
  background: #3071a9;
}

input[type=range]:focus::-ms-fill-upper {
  background: #367ebd;
}

.step-6-range::before {
  content: '';
  background: url(img/icon-hand.svg);
  background-size: contain;
  background-repeat: no-repeat;
  width: 4rem;
  height: 4rem;
  position: absolute;
  top: -5rem;
  left: 2rem;
  display: block;
}

.step-6-range::after {
  content: '';
  background: url(img/icon-board.svg);
  background-size: contain;
  background-repeat: no-repeat;
  width: 4rem;
  height: 4rem;
  position: absolute;
  top: -5rem;
  right: 2rem;
  display: block;
}

button#contactBottemSend {
  font-size: 14px;
  margin-top: 1rem;
  width: 100%;
}

.step {
  position: absolute;
  left: 0;
  transition: left .5s ease-in;
}

.step-in {
  left: 1000px;

}

.step-out {
  left: -1000px;
}

.step-hidden {
  display: none;
}

.parallax-form-inner {
  overflow: hidden;
}

.meter {
  position: absolute;
  bottom: 20px;
  left: 0;
  right: 0;
  width: 60%;
  height: 10px; /* Can be anything */
  background: #555;
  padding: 0;
  margin: auto;
  box-shadow: inset 0 -1px 1px rgba(255, 255, 255, 0.3);
}

.meter > span {
  display: block;
  height: 10px;
  background-color: var(--color-primary);
  /*  box-shadow: inset 0 2px 9px rgba(255, 255, 255, 0.3),
    inset 0 -2px 6px rgba(0, 0, 0, 0.4);*/
  position: relative;
  overflow: hidden;
  transition: width .5s ease-in;
}

.noUi-value.noUi-value-horizontal.noUi-value-large {
  font-size: 16px;
}

.btn-fade {

  text-align: center;
  transition: height 0.5s ease-in;
}

.btn-fade > button {
  color: white;
  padding: 0.5rem 1rem;
}
.step-box-content {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
}
.step-text-left {
  text-align: left;
  font-size: 1rem;
  margin-bottom: 1rem;
}
.step-confirm--textabove {
  margin-bottom: 1rem;
}
#paket-buchen {
  color: white;
}
.step-wk {
  text-align: left;
  font-size: 1rem;
}
.step-container div:not(.radio):not(.checkbox) label {
  text-align: left;
  margin-top: 0.3rem;
  margin-left: 0.4rem;
}
.table-price {
  text-align: right;
}
.step-wk-container {
  display: block;
  position: relative;
  height: 72%;
  overflow: auto;
}
.step-container-wk {
  position: relative;
  height: 100%;
  display: block;
  overflow: hidden;
}
.price-container {
  width: 100%;
  text-align: right;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  align-content: center;
  font-size: 1rem;
}
.price-text {
  text-align: right;
  width: 70%;
}
.price-value {
  text-align: right;
  width: 30%;
  font-weight: 700;
}
.step-wk-gesamt {
  padding: 1rem;
}
@media screen and (min-width: 48rem) {
  .step-container {
    width: 90%;
  }

  .parallax-form-inner {
    min-height: 45rem;
  }

  .step-boxen {
    justify-content: space-between;
    width: 70%;
  }

  .step-box {
    margin-left: 0;
  }
}

@media screen and (min-width: 64rem) {
  .parallax-form-wrapper {
    width: 75%;
  }
  .parallax--content-wk {
    width: 29%;
  }
  .step-box {
    height: 4rem;
  }
  .step-box-checkbox {
    height: 4rem;
  }
  .parallax-form-inner {
    min-height: 55rem;
  }
}

@media screen and (min-width: 80rem) {
  .parallax-form-wrapper {
    width: 65%;
  }
  .parallax--content-wk {
    width: 33%;
  }
}