.content {
  color: #2F3438;
  background: #fff;
  font-size: 1.1rem;
  margin: auto ;
  padding-top: 2rem;
  padding-bottom: 2rem;
}
.content-background-dark {
  background: #2F3438;
  color: #fff;
}
.content.layout-8 {
  padding: 0;
  margin: 0;
  background: #bdc1c5;
}
p.sub-titel {
  text-align: right;
  padding-top: 0.5rem;
}
.search article {
  margin-bottom: 1rem;
}

.search {
  margin-bottom: 2rem;
}
.content h1 {
  text-align: center;
}

.content h2 {
  font-size: 2rem;
  padding: 30px 0;
  text-align: center;
  font-weight: 100;
  font-family: sans-serif;
  margin: 0;
}

.content h2 span{
  color: #9abf00;
}

.content ul {
  margin-left: 1rem;
  list-style: none;
  padding: 0;
}

.content li {
  padding-left: 1.3em;
}

.content li:before {
  content: "»";
  display: inline-block;
  margin-left: -1.3em; /* same as padding-left set on li */
  width: 1.3em; /* same as padding-left set on li */
}

.content a {
  color: var(--color-primary);
}

.content a.btn-primary {
  color: white;
}

.content a:hover.btn-primary {
  color: white;
}

.content a:hover {
  color: color(var(--color-primary) a(60%));
}

.content ul {
  list-style: none;

}

.content > article {
  margin-bottom: calc(var(--gutter) * 2);
  margin-top: calc(var(--gutter) * 2);
}

.content .gallery {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: flex-start;
  align-content: flex-start;
  align-items: flex-start;
}

.content .gallery a {
  width: 48%;
  margin-right: 1%;
  display: block;
  position: relative;
}

.content .gallery a.content---image--one {
  width: 100%;
}

.layout-2,
.layout-3 {
  text-align: center;
}

.layout-2 .content-left {
  margin-right: 0;
  position: relative;
}

.layout-2 .content-right {
  padding: 0.5rem;
  padding-top: 0;
}

.layout-3 .content-right {
  border: solid 2px white;
  margin-left: 0;
  position: relative;
}

.layout-3 .content-right.noBorder {
  border: none;
  text-align: center;
  text-align: -webkit-center;
}

.layout-3 .content-left {
  padding: 0 0.5rem 0.5rem;
}

.layout-4 {
  text-align: left;
}

.content .gallery.layout-4-images {
  justify-content: flex-start;
}

.content .gallery.layout-4-images a {
  width: calc(96% / 4);
  margin-right: 1%;
}

.layout-4-images a img {
  border: solid 2px white;
}

tr.versions-function--entry-subhead th {
  padding: 0.5rem 0.2rem;
}
tr.versions-function--entry-subhead td,
tr.versions-function--entry-subhead th,
tr.versions-function--entry td,
tr.versions-function--entry th {
  width: 20%;
}
tr.versions-function--entry-subhead th {
  text-align: left;
  /* padding-bottom: 5rem; */
}
.versions--legend {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  align-content: center;
  flex-wrap: wrap;
}

.versions-legend--entry {
  margin-right: 1rem;
}
.col.col-1.versions--functions {
  max-width: 100%;
  display: block;
  overflow-x: auto;
}
table.table.support--table.functions--table {
  min-width: 650px;
}

table.table.support--table.functions--table tbody{
  width: 100%;

}
table.table.support--table.functions--table tr{
  width: 100%;

}
@media screen and (min-width: 48rem) {

  .content h2 {
    font-size: 4rem;
    padding: 30px 0;
    text-align: center;
    font-weight: 100;
    font-family: sans-serif;
    margin: 0;
  }

  .layout-4-images a {
    width: 19%;
    margin-right: 0.5rem;
    margin-bottom: 0.5rem;
  }

  .layout-3 .content-right.noBorder {
    text-align: left;
    text-align: -webkit-left;
  }

  .layout-2 .content-left.noBorder {
    text-align: right;
    text-align: -webkit-right;
  }

  .layout-3 {
    text-align: left;
  }

  .layout-2 {
    text-align: left;
  }

  .layout-2 .content-left {
    margin-right: 0.5rem;
  }

  .layout-3 .content-right {
    margin-left: 0.5rem;
  }
}