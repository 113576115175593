header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  z-index: 200;
  color: white;
  width: 100%;
  background: #2F3438;
  box-shadow: 0px 4px 10px 2px rgba(0, 0, 0, 0.61);
  -moz-box-shadow: 0px 4px 10px 2px rgba(0, 0, 0, 0.61);
  -webkit-box-shadow: 0px 4px 10px 2px rgba(0, 0, 0, 0.61);
}

.main-navigation {
  width: 100%;
  list-style: none;
  padding: 0;
  display: block;

  margin: 0;
}

.header-slogan {
  font-family: var(--font-normal);
  font-size: 1.4rem;
  text-transform: uppercase;
  align-self: flex-start;
  margin-right: auto;
}

.header-slogan a {
  color: white;
}

.header-slogan a:hover {
  color: var(--color-primary);
}

.header-badge {
  display: none;
  position: absolute;
  top: 10px;
  z-index: 20;
  right: 0;
}

.header-badge-img {
  display: block;
  width: 100%;
}

@media screen and (min-width: 48rem) {

  .main-navigation {
    display: flex;
    justify-content: flex-start;
    align-content: center;
    align-items: center;
    flex-wrap: wrap;
  }

  li.navigation--kdz--main-wrapper {
    color: white;
    position: absolute;
    top: 0;
    right: 0;
    list-style: none;
  }

  ul.main-navigation li {
    /*border-left: 1px solid #eee;*/
  }

  ul.main-navigation li:first-of-type {
    color: white;
    width: 100%;
  }

  ul.main-navigation li:first-of-type a:hover {
    color: var(--color-primary);
    background: none;
  }

  ul.main-navigation li a {
    color: white;
    display: block;
    padding: 10px 10px;
  }

  ul.main-navigation li.active a,
  ul.main-navigation li a:hover {
    background: var(--color-primary);
    color: white;
  }

  a.kdz--link--folgt-in-kuerze {
    text-decoration: line-through;
    color: #d2d2d2 !important;
    font-weight: 900;
    cursor: no-drop;
  }

  a.kdz--link--folgt-in-kuerze:after {
    content: '*=folgt in Kürze';
    font-size: 8px;
    position: absolute;
    right: 0;
    top: 18px;
    color: #555;
    font-weight: 100;
  }


  .header-badge {
    display: block;
  }
}
@media screen and (min-width: 64rem) {




}
@media screen and (min-width: 100rem) {
  ul.main-navigation li:first-of-type {
    width: auto;
  }
  .main-navigation {
    justify-content: flex-end;
  }
  ul.main-navigation li a {
    color: white;
    padding: 15px 15px;
  }
  .main-nav {
    padding-left: 2rem;
    padding-right: 2rem;
  }
}