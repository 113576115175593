.meinungen-wrapper {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.meinungen-inner {
  margin-top: 1rem;
  display: block;
  position: relative;
}
p.meinungen-subtitel {
  text-align: center;
  margin-bottom: 5px;
}
.meinungen-wrapper h2 {
  margin-top: 0;
}
.meinungen-wrapper h2::after {
  content: '';
  width: 4rem;
  height: 2px;
  display: block;
  position: relative;
  margin: 1rem auto 0;
  /* Permalink - use to edit and share this gradient: http://colorzilla.com/gradient-editor/#ffd100+1,ffffff+78 */
  background: var(--color-primary); /* Old browsers */
}
div#mmeinungen {
  background: transparent;
  margin-left: 0;
}

.swiper-wrapper {
  background: transparent;
}

.swiper-slide.meinungs-slide {
  background: transparent;
}

.meinungen-img-wrapper {
  position: absolute;
  top: 0;
}

.meinungen-flex {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-wrap: wrap;
}

.meinungen-right {
  display: none;
  position: relative;
  width: 100%;
}

.meinungen-left {
  width: 100%;
}
.meinungen-img-wrapper img {
  width: 100%;
  max-width: 100%;
  height: auto;
}

.meinungsbox {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: center;
  align-content: center;
  align-items: stretch;
  padding: .5rem 1rem;
  width: 100%;
  height: 100%;
}

.meinungen-img {
  width: 100%;
}

.meinungen-text {
  width: 60%;
  text-align: center;
  font-family: var(--font-light);
  height: 100%;
  position: relative;
  display: block;
}
.meinungen-bild {
  display: flex;
  width: 10rem;
  height: 10rem;
  justify-content: center;
  align-self: center;
  overflow: hidden;
  margin-bottom: var(--gutter);
  margin-left: auto;
  margin-right: auto;
}

.meinungen-bild img {
  height: 100%;
  width: auto;
}

.meinungen-text p {
  margin-bottom: calc(var(--gutter) * 0.5);
}

.meinungen-name {
  font-size: 1.2rem;
  line-height: 1.2;
  color: var(--color-primary);
  font-family: var(--font-light);
}
