.kacheln {
  margin-top: var(--gutter);
}

.boxen {
  display: -webkit-flex;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
  justify-content: space-between;
  align-content: flex-start;
  align-items: stretch;
  padding-bottom: 2rem;
}

.box {
  width: 100%;
  text-align: center;
  margin-bottom: 1rem;
}
.box-inner {
  overflow: hidden;
  display: block;
  position: relative;
}

.box-leistung--content {
  margin-top: 0;
}

.box-leistung--inner h2 {
  font-family: var(--font-normal);
  color: white;
  text-align: center;
}

.box-leistung--content {
  position: relative;
  display: block;
  padding: 1rem;
  margin-top: 0;
}
.box-inner:hover  .box-leistung--content {
  bottom: 0;
}

.box-leistung--inner p {
  margin-top: 1rem;
}
.box-leistung--inner .btn {
  margin-top: 1rem;
  display: inline-block;
}

.box-leistung--img {
  height: auto;
  display: block;
  position: relative;
  width: 8rem;
  text-align: center;
  margin: auto;
}
.box-leistung--img img {
  margin: auto;
}


@media screen and (min-width: 35.5rem) {
  .box-leistung--content {
    /*top: 60%;*/
  }
  .box {
    width: calc(98% / 2);

  }
}

@media screen and (min-width: 64rem) {
  .box {
    width: calc(97% / 3);
    margin-bottom: 0;
  }
}

