.slider-wrapper {
  margin-top: 4rem;
}
.swiper-container {
  width: 100%;
  height: 100%;
}

.swiper-slide {
  text-align: center;
  font-size: 18px;
  background: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}
.slider--img{
  width: 100%;
  display: block;
  position: relative;
}
.slider--img img{
  width: 100%;
  display: block;
}
.slider--img-overlay {
  position: absolute;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  z-index: 10;
}
.slider--img-overlay img {
  display: block;
  position: relative;
  width: 100%;
  height: 100%;
}
@media screen and (min-width: 48rem) {
  .slider-wrapper {
    margin-top: 3rem;
  }
}