/* allgemeine Infos und viewport-sx 320px */

nav.main-nav {
  width: 100%;
}
.header-logo-mobil a {
  font-family: var(--font-normal);
  font-size: 1.3rem;
  padding-left: 1rem;
}
.mobile-hamburger.pure-g {
  position: relative;
  height: 4rem;
  display: flex;
  justify-content: space-between;
  width: 100%;
  align-items: center;
  align-content: center;
}


button.hamburger-button.mobile-navigation-button {
  height: 3rem;
  width: 3rem;
  margin-right: 1rem;
  padding: 5px;
}

button.hamburger-button svg {
  height: 2rem;
  width: 2rem;
}

.mobile-navigation {
  font-size: var(--font-size);
  overflow-y: scroll;
  overflow-x: scroll;
}

.mobile-navigation a {
  padding: calc(var(--gutter) / 2);
  width: 100%;
  position: relative;
  display: flex;
  justify-content: flex-start;
  flex-wrap: wrap;
  align-items: center;
  align-content: center;
}

.mobile-navigation .header-logo img {
  margin-right: auto;
  margin-left: auto;
}

.mobile-navigation .first-layer {
  color: var(--color-primary);
}

.mobile-navigation .first-layer:hover {
  color: var(--color-primary);
}

.mobile-navigation .first-layer + ul {
  display: none;
}

.mobile-navigation .first-layer.open + ul {
  display: block;
}

.mobile-navigation .first-layer::before {
  border: none;
}

.mobile-navigation .dropdown-list-item {
  border: none;
  padding: 0;
}

.mobile-navigation .dropdown-list-item a {
  margin-bottom: 0;
}

.mobile-navigation .dropdown {
  background-color: transparent;
  position: relative;
  padding: 0;
  padding-left: calc(var(--gutter) * 2);
}

.mobile-navigation .dropdown-link {
  color: white;
}

.main-navigation li:hover > .dropdown-link {
  color: var(--color-primary);
}

.mobile-navigation li:hover .dropdown-link:hover {
  color: var(--color-grey-dark);
}

.mobile-navigation .dropdown-link-layer-2 {
  color: white;
}

.mobile-navigation li:hover .dropdown-link-layer-2 {
  font-size: var(--font-size);
  color: #ffffff;
}

.mobile-navigation li:hover .dropdown-link-layer-2:hover {
  font-size: var(--font-size);
  color: var(--color-grey-dark);
}

.mobile-navigation .dropdown-list-item ul {
  list-style-type: none;
  margin-bottom: 0;
}

.mobile-navigation .dropdown-list-item li {
  padding: 0;
}
