
.sub-footer {
  background: var(--color-primary);
  padding-bottom: 1rem;
  padding-top: 1rem;
  text-align: center;
}

.sub-footer ul {
  list-style: none;
  display: inline-flex;
  flex-wrap: wrap;
  margin-bottom: 0;
  justify-content: center;
}

.sub-footer ul li {
  padding-right: 1rem;
  color: white;
}
.sub-footer ul li a{
  color: white;
}
.logo-footer {
  background: url("img/header.jpg") no-repeat center center;
  background-size: cover;
  display: block;
  height: 100%;
  position: relative;
}

.newsletter-mail-container {
  display: flex;
  justify-content: flex-start;
}
/*.logo-footer::before {
  content: "";
  background: color(var(--color-primary) a(80%));
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 1;
}*/
.footer-boxen {
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: row;
  padding-bottom: 3rem;
  padding-top: 3rem;
}
.footer-box {
  z-index: 5;
  width: 100%;
  display: flex;
  justify-content: center;
  align-content: center;
  align-items: center;
  flex-wrap: wrap;
  position: relative;
  border: solid 1px white;
  color: white;
  height: 22rem;
  margin-bottom:3rem;
  padding: 0.5rem;
  background: rgba(255,255,255, 0.2);
}

.footer-box-img-container {
  position: absolute;
  top: -2rem;
  margin-right: auto;
  margin-left: auto;
  display: block;
  left: 0;
  right: 0;
}
.footer-box-img {
  width: 4rem;
  height: 4rem;
  display: block;
  position: relative;
  border-radius: 4rem;
/*  border: solid 5px color(var(--color-primary) a(30%));
  background: color(var(--color-primary) a(30%));*/
  margin: auto;
}
.footer-box-inner {
  padding-top: 1rem;
  width: 100%
}
.footer-box-tel p {
  color: white;
  font-size: 1.4rem;
  font-family: var(--font-light);
}
.footer-box-tel a {
  color: white;
  font-size: 2rem;
}
.footer-box-mail .footer-box-inner {
 padding-right: 2.5rem;
  padding-left: 2.5rem;
}
.footer-box-mail p {
  color: white;
  font-size: 1.4rem;
  font-family: var(--font-light);
}
.footer-box-mail input,
.footer-box-mail .pure-form input[type="email"],
.footer-box-mail .pure-form input[type="text"]
{
  border: solid 1px white;
  background: transparent;
  color: white;
  box-shadow:none;
}
.footer-box-mail button {
  background: white;
  display: block;
  width: 100%;
}
@media screen and (min-width: 64rem) {
  .footer-box {
    width: 45%;
    margin-bottom: 0;
    height: 18rem;
  }
  .footer-box:first-of-type {
    margin-right: 10%;
  }
}